import { environment } from '../../../../../../../shared/src/lib/environments/environment';
import { NavItem } from '../../vertical/sidebar/nav-item/nav-item';

export const navItems: NavItem[] = [
  {
    navCap: 'Home',
  },
  ...(environment.enableDashboard ? [
    {
      displayName: 'Dashboard',
      iconName: '',
      route: '/starter',
    }
  ]:[] ),

  {
    displayName: 'Users',
    iconName: '',
    route: 'home/users',
  },
  // {
  //   displayName: 'Reports',
  //   iconName: '',
  //   route: 'home/report',
  // },
  {
    displayName: 'Account Statement',
    iconName: '',
    route: 'home/account-statement',
  },
  {
    displayName: 'Betting P/L',
    iconName: '',
    route: 'home/settled-markets',
  },
  {
    displayName: 'Live PnL',
    iconName: '',
    route: 'home/live-markets',
  },
  {
    displayName: 'Bet Info',
    iconName: '',
    route: 'home/bet-info',
  },
  {
    displayName: 'White Labels',
    iconName: '',
    route: 'home/white-label',
  },

  {
    displayName: 'Betfair Accounts',
    iconName: '',
    route: 'configuration/betfair-accounts',
  },

  {
    displayName: 'Commission',
    iconName: '',
    route: 'home/commission',
  },
  {
    displayName: 'Risk Taking',
    iconName: '',
    route: 'home/risk',
  },
  {
    displayName: 'Exchange Rate',
    iconName: '',
    route: 'configuration/exchange-rate',
  },

  {
    displayName: 'Supported Countries',
    iconName: '',
    route: 'settings/supported-countries',
  },
  {
    displayName: 'Supported Sports',
    iconName: '',
    route: 'settings/supported-sports',
  },
  {
    displayName: 'Roles',
    iconName: '',
    route: 'settings/roles',
  },
  {
    displayName: 'Change Password',
    iconName: '',
    route: 'home/reset-password',
  }
  //Settings
  // {
  //   displayName: 'Settings',
  //   iconName: 'layout',
  //   route: 'settings',
  //   children: [
  //     {
  //       displayName: 'Supported Countries',
  //       iconName: 'point',
  //       route: 'settings/supported-countries',
  //     },
  //     {
  //       displayName: 'Supported Sports',
  //       iconName: 'point',
  //       route: 'settings/supported-sports',
  //     },
  //     {
  //       displayName: 'Roles',
  //       iconName: 'point',
  //       route: 'settings/roles',
  //     },
  //   ]
  // },


  //Nested menu example
  // {
  //   navCap: 'Other',
  // },
  // {
  //   displayName: 'Menu Level',
  //   iconName: 'box-multiple',
  //   route: 'menu-level',
  //   children: [
  //     {
  //       displayName: 'Menu 1',
  //       iconName: 'point',
  //       route: '/menu-1',
  //       children: [
  //         {
  //           displayName: 'Menu 1',
  //           iconName: 'point',
  //           route: '/menu-1',
  //         },
  //
  //         {
  //           displayName: 'Menu 2',
  //           iconName: 'point',
  //           route: '/menu-2',
  //         },
  //       ],
  //     },
  //
  //     {
  //       displayName: 'Menu 2',
  //       iconName: 'point',
  //       route: '/menu-2',
  //     },
  //   ],
  // },
];


