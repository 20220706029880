<mat-toolbar class="topbar horizontal-topbar">
  <div class="container horizontal-header">
    <div class="d-none d-sm-flex">
      <app-branding></app-branding>
    </div>

    <!-- Mobile Menu -->
    <button mat-icon-button (click)="toggleMobileNav.emit()" class="d-block d-lg-none">
      <i-tabler name="menu-2" class="icon-20 d-flex"></i-tabler>
    </button>
    <!-- --------------------------------------------------------------- -->

    <!-- --------------------------------------------------------------- -->
    <!--  Search -->
    <!-- --------------------------------------------------------------- -->
<!--    <button mat-icon-button (click)="openDialog()" class="d-flex">-->
<!--      <i-tabler name="search" class="icon-20 d-flex"></i-tabler>-->
<!--    </button>-->

    <span class="flex-1-auto "></span>

    <!-- Mobile Menu -->
<!--    <button mat-icon-button (click)="toggleMobileFilterNav.emit()" class="d-flex d-lg-none justify-content-center">-->
<!--      <i-tabler name="grid-dots" class="icon-20 d-flex"></i-tabler>-->
<!--    </button>-->
<!--    <button mat-icon-button [matMenuTriggerFor]="profilemenu" aria-label="Notifications">-->
<!--      <img src="/assets/images/profile/user-1.jpg" class="rounded-circle object-cover" width="35" />-->
<!--    </button>-->

    <h6 class="f-s-16 f-w-600 m-0">{{userData.NAME}}</h6>
    <span class="f-s-16 f-w-100">({{userData.ROLE}})</span>
    <div class="p-y-12 p-x-32">
      <button (click)="logOut()" mat-raised-button color="primary" class="fetch-button">Log Out</button>
    </div>
    <mat-menu #profilemenu="matMenu" xPosition="before" class="topbar-dd cardWithShadow">
      <div class="p-x-32 p-y-16">
        <h6 class="f-s-16 f-w-600 m-0">User Profile</h6>

        <div class="d-flex align-items-center p-b-24 b-b-1 m-t-16">
          <img src="/assets/images/profile/user-1.jpg" class="rounded-circle" width="95" />
          <div class="m-l-16">
            <h6 class="f-s-14 f-w-600 m-0">{{userData.NAME}}</h6>
            <span class="f-s-14 d-block m-b-4">{{userData.ROLE}}</span>
            <span class="d-flex align-items-center">
              <i-tabler name="mail" class="icon-15 m-r-4"></i-tabler>
              info&#64;modernize.com
            </span>
          </div>
        </div>
      </div>
      <div class="p-x-32">
        @for(profile of profiledd; track profile) {
        <a class="p-y-16 text-decoration-none d-block text-hover-primary" [routerLink]="[profile.link]">
          <div class="d-flex align-items-center">
            <button mat-mini-fab class="text-primary bg-light-primary shadow-none rounded">
              <img [src]="profile.img" width="20" />
            </button>

            <div class="m-l-16">
              <h5 class="f-s-14 f-w-600 m-0 textprimary mat-subtitle-1 hover-text">
                {{ profile.title }}
              </h5>
              <span class="mat-body-1">{{ profile.subtitle }}</span>
            </div>
          </div>
        </a>
        }
      </div>
      <div class="p-y-12 p-x-32">
        <a (click)="logOut()" mat-stroked-button color="primary" class="w-100">Logout</a>
      </div>
    </mat-menu>
  </div>
</mat-toolbar>
